@charset "UTF-8";
.downloads__overview-row {
  margin-top: 10px;
}

.downloads__overview-title {
  color: var(--link-color);
  padding-left: 25px;
}
.downloads__overview-title::before {
  content: "📄 ";
  display: inline-block;
  margin-left: calc(0px - var(--downloads-icon-width));
  width: var(--downloads-icon-width);
}