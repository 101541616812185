.slideshow__container {
  width: 100%;
}
.slideshow__container--left {
  float: left;
  margin-right: 20px;
}
.slideshow__container--center {
  margin-left: auto;
  margin-right: auto;
}
.slideshow__container--right {
  float: right;
  margin-left: 20px;
}
.slideshow__container--hero .slideshow {
  height: var(--slideshow-hero-height);
  margin: 0;
  max-height: calc(70vh - var(--header-height));
}

.slideshow {
  background: var(--header-background-color);
  display: inline-block;
  line-height: 0;
  margin: 20px 0;
  position: relative;
  width: 100%;
  z-index: 0;
}

.slideshow__image {
  background-size: cover;
  display: block;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition-property: opacity;
  transition-timing-function: ease-in-out;
  width: 100%;
  z-index: 1;
}
.slideshow__image--current {
  opacity: 1;
  z-index: 2;
}