.cta__button {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 10px;
}
.cta__button a {
  background-color: var(--button-background-color);
  border: 1px solid var(--button-background-color);
  border-radius: 2px;
  color: var(--button-font-color);
  padding: 6px 10px;
  text-decoration: none;
}
.cta__button a:hover {
  text-decoration: none;
}
.cta__button a:active {
  background-color: transparent;
  color: var(--button-background-color);
}