:root {
  --header-height: 40px;
  --header-background-color: #01194b;
  --header-font-color: #f4effd;
  --header-blocks-left: 225px;
  --header-blocks-top: 12px;
  --header-blocks-width: 36.8px;
  --header-blocks-height: 9.6px;
  --menu-font-size: 16px;
  --menu-padding: 0 20px;
  --menu-transition-time: .3s;
  --menu-toggle-size: 30px;
  --menu-right: env(safe-area-inset-right);
  --menu-top: var(--header-height);
  --menu-item-padding: 0 20px;
  --menu-item-height: 40px;
  --menu-subitem-height: 40px;
  --menu-opacity: 0;
  --menu-pointer-events: none;
  --menu-toggle-display: block;
  --menu-transition: opacity var(--menu-transition-time), transform var(--menu-transition-time);
  --menu-z-index: var(--z-index-menu-under);
  --menu-flex-direction: column;
  --menu-max-height: calc(100vh - var(--header-height));
  --menu-overflow: auto;
  --menu-transform: translateX(100%);
  --submenu-opacity: 1;
  --submenu-pointer-events: auto;
  --submenu-position: static;
  --page-width: 1050px;
  --page-padding: 20px;
  --page-padding-left: calc(20px + env(safe-area-inset-left));
  --page-padding-right: calc(20px + env(safe-area-inset-right));
  --page-grid-columns: [left] var(--page-padding-left) 1fr [article-left aside-left] minmax(auto, var(--page-width)) [article-right aside-right] 1fr var(--page-padding-right) [right];
  --widget-column-width: 250px;
  --footer-padding: 5px;
  --footer-font-size: .8em;
  --footer-flex-direction: column;
  --cookieconsent-flex-direction: column;
  --cookieconsent-open-transform: rotate(90deg);
  --cookieconsent-intro-width: 100%;
  --cookieconsent-info-display: none;
  --cookieconsent-info-position: relative;
  --cookieconsent-info-left: 0;
  --cookieconsent-info-right: 0;
  --cookieconsent-choice-spacing: 0;
  --cookieconsent-choice-margin: 10px;
  --slideshow-hero-height: 75vw;
  --image-float-left: none;
  --image-float-right: none;
  --image-float-display: block;
  --image-margin-left: 0 0 1em;
  --image-margin-right: 0 0 1em;
  --form-fieldgroup-align: normal;
  --form-fieldgroup-flex-direction: column;
  --form-fields-width: 100%;
  --button-background-color: #01194b;
  --button-font-color: #f4effd;
  --input-border-color: #999999;
  --input-element-color: #01194b;
  --input-transition-time: .2s;
  --downloads-icon-width: 23px;
  --gallery-icon-size: 30px;
  --memberlist-grid: [voice name address numbers email] 1fr;
  --memberlist-address-grid: [street-nr zip-city country] 1fr;
  --memberlist-numbers-grid: [phone mobile] 1fr;
  --memberlist-voice-margin: 30px;
  --memberlist-member-margin: 10px;
  --memberlist-subrow-margin: 0;
  --memberlist-subrow-voice-margin: 0;
  --memberlist-subrow-indent: 20px;
  --memberlist-numbers-margin: 0;
  --sheetmusic-letter-width: 20px;
  --sheetmusic-icon-width: 23px;
  --sheetmusic-row-flex-wrap: wrap;
  --sheetmusic-composer-flex: 1;
  --sheetmusic-composer-padding: 0;
  --sheetmusic-composer-width: auto;
  --sheetmusic-title-padding: calc(var(--sheetmusic-letter-width) + var(--sheetmusic-icon-width));
  --sheetmusic-title-width: 100%;
  --link-color: #0000cc;
  --z-index-gallery: 5;
  --z-index-menu-over: 4;
  --z-index-header: 3;
  --z-index-menu-under: 2;
  --z-index-main: 0;
}
@media (min-width: 450px) {
  :root {
    --header-blocks-left: 230px;
    --header-blocks-width: 41.4px;
    --header-blocks-height: 10.8px;
    --footer-flex-direction: row;
    --cookieconsent-flex-direction: row;
    --cookieconsent-flex-wrap: wrap;
    --slideshow-hero-height: 50vw;
    --image-float-left: left;
    --image-float-right: right;
    --image-float-display: initial;
    --image-margin-left: 0 0 1em 1em;
    --image-margin-right: 0 1em 1em 0;
    --form-fieldgroup-align: baseline;
    --form-fieldgroup-flex-direction: row;
    --form-fields-width: auto;
    --sheetmusic-row-flex-wrap: nowrap;
    --sheetmusic-composer-flex: none;
    --sheetmusic-composer-padding: var(--sheetmusic-icon-width);
    --sheetmusic-composer-width: 250px;
    --sheetmusic-title-padding: 0;
    --sheetmusic-title-width: auto;
  }
}
@media (min-width: 750px) {
  :root {
    --header-height: 60px;
    --header-blocks-left: 340px;
    --header-blocks-top: 20px;
    --header-blocks-width: 46px;
    --header-blocks-height: 12px;
    --menu-padding: 0 0 0 20px;
    --menu-top: var(--header-height);
    --menu-flex-direction: row;
    --menu-max-height: none;
    --menu-overflow: visible;
    --menu-transform: translate(0, -100%);
    --page-padding: 30px;
    --page-padding-left: calc(30px + env(safe-area-inset-left));
    --page-padding-right: calc(30px + env(safe-area-inset-right));
    --page-grid-columns: [left] var(--page-padding-left) 1fr [article-left] minmax(auto, calc(var(--page-width) - var(--widget-column-width) - var(--page-padding))) [article-right] var(--page-padding) [aside-left] var(--widget-column-width) [aside-right] 1fr var(--page-padding-right) [right];
    --footer-padding: 10px;
    --footer-font-size: 1em;
    --cookieconsent-flex-wrap: nowrap;
    --cookieconsent-intro-width: auto;
    --cookieconsent-open-transform: rotate(-90deg);
    --cookieconsent-info-display: block;
    --cookieconsent-info-position: absolute;
    --cookieconsent-info-left: calc(max(var(--page-padding-left), 50vw - var(--page-width) / 2));
    --cookieconsent-info-right: calc(max(var(--page-padding-right), 50vw - var(--page-width) / 2));
    --cookieconsent-choice-spacing: 15px;
    --cookieconsent-choice-margin: 0;
    --memberlist-grid: [voice] max-content [name] 1fr [address] 1fr [numbers] max-content [email] max-content;
    --memberlist-subrow-margin: var(--memberlist-member-margin);
    --memberlist-subrow-voice-margin: var(--memberlist-voice-margin);
    --memberlist-subrow-indent: 0;
  }
}
@media (min-width: 1050px) {
  :root {
    --menu-padding: 0;
    --menu-right: calc(50vw - var(--page-width) / 2);
    --menu-top: 0;
    --menu-item-height: 60px;
    --menu-toggle-display: none;
    --menu-pointer-events: auto;
    --menu-opacity: 1;
    --menu-transform: translate(0);
    --menu-transition: none;
    --menu-z-index: var(--z-index-menu-over);
    --submenu-opacity: 0;
    --submenu-pointer-events: none;
    --submenu-position: absolute;
    --page-padding: 50px;
    --page-padding-left: calc(50px + env(safe-area-inset-left));
    --page-padding-right: calc(50px + env(safe-area-inset-right));
    --memberlist-grid: [voice] max-content [name] 1fr [address] 3fr [numbers] max-content [email] max-content;
    --memberlist-address-grid: [street-nr] 1fr [zip-city country] 1fr;
    --memberlist-numbers-grid: [phone] 1fr [mobile] 1fr;
    --memberlist-numbers-margin: var(--memberlist-member-margin);
  }
  :root .header {
    --page-padding-left: calc(50% - var(--page-width) / 2);
  }
}

html {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header {
  background: var(--header-background-color);
  color: var(--header-font-color);
  height: var(--header-height);
  left: 0;
  padding-left: var(--page-padding-left);
  position: fixed;
  right: 0;
  top: 0;
  z-index: var(--z-index-header);
}

.header__logo {
  background: url("web/img/logo.png") left center/contain no-repeat;
  display: block;
  height: var(--header-height);
  max-width: calc(100% - var(--header-height) - env(safe-area-inset-left) - env(safe-area-inset-right));
  position: relative;
  width: 325px;
}

#menu-open {
  pointer-events: none;
  position: absolute;
  visibility: hidden;
}

.menu {
  color: var(--header-font-color);
  display: flex;
  flex-direction: var(--menu-flex-direction);
  font-size: var(--menu-font-size);
  font-weight: 400;
  max-height: var(--menu-max-height);
  opacity: var(--menu-opacity);
  overflow: var(--menu-overflow);
  pointer-events: var(--menu-pointer-events);
  position: fixed;
  right: var(--menu-right);
  top: var(--menu-top);
  transform: var(--menu-transform);
  transition: var(--menu-transition);
  z-index: var(--menu-z-index);
}
#menu-open:checked + .menu {
  opacity: 1;
  pointer-events: auto;
  transform: translate(0);
}

.menu__item {
  background: var(--header-background-color);
  display: inline-block;
  flex: none;
  position: relative;
  z-index: 0;
}
.menu__item a {
  color: inherit;
  display: inline-block;
  height: var(--menu-item-height);
  line-height: var(--menu-item-height);
  padding: var(--menu-item-padding);
  text-decoration: none;
  white-space: nowrap;
}

.menu__submenu {
  background: var(--header-background-color);
  line-height: var(--menu-subitem-height);
  opacity: var(--submenu-opacity);
  padding: var(--menu-padding);
  pointer-events: var(--submenu-pointer-events);
  position: var(--submenu-position);
  left: 0;
  top: var(--menu-item-height);
  transition: opacity var(--menu-transition-time);
  z-index: -1;
}
.menu__submenu a {
  height: var(--menu-subitem-height);
  line-height: var(--menu-subitem-height);
}
.menu__item:hover .menu__submenu {
  opacity: 1;
  pointer-events: auto;
}
html:not(.canteklaer--loggedin) .menu__item--intern .menu__submenu {
  display: none;
}

.menu__toggle {
  background: #ffffff;
  cursor: pointer;
  display: var(--menu-toggle-display);
  height: var(--header-height);
  -webkit-mask: url("web/img/menu.svg") center/var(--menu-toggle-size) no-repeat;
  mask: url("web/img/menu.svg") center/var(--menu-toggle-size) no-repeat;
  position: absolute;
  right: env(safe-area-inset-right);
  top: 0;
  width: var(--header-height);
}

.main {
  display: grid;
  flex: 1;
  grid-auto-rows: min-content;
  grid-template-columns: var(--page-grid-columns);
  margin-bottom: 20px;
  margin-top: var(--header-height);
  z-index: var(--z-index-main);
}
.slideshow__container--hero {
  grid-column: left/right;
}

.main__title,
.main__contents {
  grid-column: article-left/article-right;
}

.main--fullwidth .main__contents {
  grid-column: article-left/aside-right;
}

.main__widgets {
  grid-column: aside-left/aside-right;
}

.footer {
  background: var(--header-background-color);
  color: var(--header-font-color);
  display: flex;
  flex: none;
  font-size: var(--footer-font-size);
  justify-content: center;
  padding: var(--footer-padding) var(--page-padding-left) var(--footer-padding) var(--page-padding-right);
}

.footer__content {
  display: flex;
  flex: 1;
  flex-direction: var(--footer-flex-direction);
  max-width: var(--page-width);
}

.footer__text {
  flex: 1;
}

.footer__cookies {
  color: var(--header-font-color);
  flex: none;
}

.header__logo::after,
.main__contents > .heading2:not(:first-child)::before,
.main__widgets > .heading2:not(:first-child)::before,
.blocks-before::before,
.main__contents > .normal:last-child::after {
  background: linear-gradient(to right, #b10507, #b10507 26.09%, transparent 26.09%, transparent 36.96%, #dadf11 36.96%, #dadf11 63.04%, transparent 63.04%, transparent 73.91%, #9999ff 73.91%, #9999ff);
  content: "";
  display: block;
  height: 12px;
  pointer-events: none;
  width: 46px;
}

.header__logo::after {
  height: var(--header-blocks-height);
  left: var(--header-blocks-left);
  position: absolute;
  top: var(--header-blocks-top);
  width: var(--header-blocks-width);
}

.main__contents > .heading2:not(:first-child)::before,
.main__widgets > .heading2:not(:first-child)::before,
.blocks-before::before {
  margin-bottom: 20px;
}

.main__contents > .normal:last-child::after {
  margin-top: 25px;
}

.canteklaer-dialog {
  background: white;
  padding: 20px;
}
.canteklaer-dialog__modalbg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  white-space: nowrap;
  vertical-align: middle;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.canteklaer-dialog__modalbg:before {
  display: inline-block;
  content: "";
  vertical-align: middle;
  line-height: 100%;
  height: 100%;
  width: 0;
}
.canteklaer-dialog__holder {
  text-align: left;
  display: inline-block;
  white-space: normal;
  font-family: inherit;
  color: inherit;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}