.wh-requireconsent {
  position: relative;
  z-index: 0;
}

.wh-requireconsent__overlay {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
.wh-requireconsent__overlay[hidden] {
  display: none;
}
.wh-video .wh-requireconsent__overlay {
  background: rgba(255, 255, 255, 0.9);
}

.wh-requireconsent__overlay-content {
  flex: none;
  text-align: center;
  max-width: 100%;
  padding: 10px;
}

.cookieconsent {
  align-items: baseline;
  background: var(--header-background-color);
  bottom: 0;
  color: var(--header-font-color);
  display: flex;
  flex-direction: var(--cookieconsent-flex-direction);
  flex-wrap: var(--cookieconsent-flex-wrap);
  left: 0;
  opacity: 0;
  padding: 10px max(var(--page-padding-right), 50vw - var(--page-width) / 2) 10px max(var(--page-padding-left), 50vw - var(--page-width) / 2);
  pointer-events: none;
  position: fixed;
  right: 0;
  transition: opacity var(--menu-transition-time);
}
html.canteklaer--cookieconsent .cookieconsent {
  opacity: 1;
  pointer-events: auto;
}

.cookieconsent__intro {
  width: var(--cookieconsent-intro-width);
}

.cookieconsent__choice {
  margin-bottom: var(--cookieconsent-choice-margin);
  margin-left: var(--cookieconsent-choice-spacing);
  white-space: nowrap;
}
.cookieconsent__choice .wh-styledinput input[type=checkbox] {
  top: 5px;
}

.cookieconsent__open {
  -webkit-appearance: none;
  appearance: none;
  background: #ffffff;
  border: none;
  cursor: pointer;
  display: inline-block;
  height: 20px;
  margin-left: 5px;
  -webkit-mask: url("../../web/img/right.svg") center/cover no-repeat;
  mask: url("../../web/img/right.svg") center/cover no-repeat;
  position: relative;
  top: 7px;
  transition: transform var(--menu-transition-time);
  vertical-align: baseline;
  width: 20px;
}
.cookieconsent__open:checked {
  transform: var(--cookieconsent-open-transform);
}

.cookieconsent__choice-info {
  background: var(--header-background-color);
  bottom: 100%;
  display: var(--cookieconsent-info-display);
  left: var(--cookieconsent-info-left);
  opacity: 0;
  padding: 10px 10px calc(10px - var(--cookieconsent-choice-margin));
  pointer-events: none;
  position: var(--cookieconsent-info-position);
  right: var(--cookieconsent-info-right);
  transition: opacity var(--menu-transition-time);
  visibility: hidden;
  white-space: normal;
}
.cookieconsent__open:checked + .cookieconsent__choice-info {
  display: block;
  opacity: 1;
  pointer-events: auto;
  visibility: visible;
}

.cookieconsent__button {
  margin-left: var(--cookieconsent-choice-spacing);
  width: var(--cookieconsent-intro-width);
}
.cookieconsent__button.wh-styledinput button {
  background-color: var(--button-font-color);
  border: 1px solid var(--button-font-color);
  color: var(--button-background-color);
}
.cookieconsent__button.wh-styledinput button:active {
  background-color: var(--button-background-color);
  color: var(--button-font-color);
}