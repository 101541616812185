.wh-gallery-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  touch-action: none;
}
.wh-gallery-modal__title {
  margin: 25px 0 30px;
  padding: 0 80px;
  color: #fff;
  text-align: center;
}
.wh-gallery-modal__counter {
  position: absolute;
  top: 26px;
  left: 30px;
  font-weight: 400;
}
.wh-gallery-modal__close {
  cursor: pointer;
  position: absolute;
  font-size: 30px;
  right: 30px;
  top: 30px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  z-index: 1;
}
.wh-gallery-modal__next, .wh-gallery-modal__previous {
  position: absolute;
  top: 80px; /* keep space for counter and close btn */
  bottom: 80px;
  width: 60px;
  cursor: pointer;
  font-size: 54px;
  display: flex;
  align-items: center;
  transition: transform 0.3s;
}
.wh-gallery-modal--firstslide .wh-gallery-modal__previous, .wh-gallery-modal--lastslide .wh-gallery-modal__next {
  cursor: default;
  opacity: 0.3;
  pointer-events: none;
}
.wh-gallery-modal__next {
  right: 0;
  justify-content: flex-start;
}
.wh-gallery-modal__next > *:hover {
  transform: translateX(5px);
}
.wh-gallery-modal__previous {
  left: 0;
  justify-content: flex-end;
}
.wh-gallery-modal__previous > *:hover {
  transform: translateX(-5px);
}
.wh-gallery-modal__slides {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.wh-gallery-modal__image {
  display: inline-block;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transition: transform 500ms cubic-bezier(0.5, 0, 0, 1), width 200ms, height 200ms;
}
.wh-gallery-modal__image > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.8s;
}
.wh-gallery-modal__imagesizer > span {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  font-size: 50px;
  opacity: 0.5;
  display: none;
}
.wh-gallery-modal__image--loading .wh-gallery-modal__imagesizer > span {
  display: block;
}
.wh-gallery-modal__image__title {
  padding: 0;
  position: absolute;
  font-style: italic;
  top: 100%;
  left: 0;
  right: 0;
  margin-top: 20px;
  color: #fff;
  text-align: center;
  font-size: 15px;
  line-height: 22px;
}
@media (max-width: 600px) {
  .wh-gallery-modal .next, .wh-gallery-modal .previous {
    justify-content: center;
  }
}