/* Standaard styling voor richdocument-bestanden, die ook in de editor wordt getoond */

html
{
  color: #01143f;
  font: 300 11.5pt/17pt "lucida grande", "lucida sans unicode", "lucida sans", verdana, arial, sans-serif;
}

h1
{
  font-size: 1.5em;
  font-weight: 900;
  margin-top: 30px;
}

h2
{
  font-size: 1.3em;
  font-weight: 700;
  margin-top: 30px;
}

h3
{
  font-size: 1.1em;
  font-weight: 700;
  margin-top: 15px;
}

p
{
  margin-top: 10px;
}

h1:first-child,
h2:first-child,
h3:first-child,
p:first-child
{
  margin-top: 30px;
}

ul
{
  margin: 10px 0 0 0;
  padding: 0 0 0 20px;
}

li
{
  margin: 0;
  padding: 0;
}

li + li
{
  margin-top: 10px;
}

ul ul
{
  list-style: none;
  padding-left: 10px;
}

a
{
  color: #0049dc;
  text-decoration-color: transparent;
  transition: text-decoration-color 150ms;
}

a:hover
{
  text-decoration-color: #0049dc;
}

/* Toon een pijltje achter links die in een nieuw tabblad openen, behalve (vergrotingen van) afbeeldingen en e-maillinks */
a[target]:not([href$=".jpg"]):not([href$=".png"]):not([href^="x-"])::after
{
  content: "\00a0↗️";
  display: inline-block;
}

b
{
  font-weight: 700;
}

.wh-rtd__img
{
  max-width: 100%;
  height: auto;
}

.wh-rtd__img--floatleft
{
  margin: 0 1em 1em 0;
}

.wh-rtd__img--floatright
{
  margin: 0 0 1em 1em;
}

.wh-video
{
  margin-top: 10px;
}

.wh-video__playbutton::before
{
  --button-height: 60px;
  --button-width: 60px;

  background: var(--button-background-color);
  content: "";
  height: var(--button-height);
  left: calc(50% - (var(--button-width) / 2));
  position: absolute;
  top: calc(50% - (var(--button-height) / 2));
  width: var(--button-width);
}

.wh-video__playbutton::after
{
  --button-height: 30px;
  --button-width: 30px;

  align-items: center;
  background: var(--button-font-color);
  content: "";
  display: flex;
  font-size: 20px;
  height: var(--button-height);
  justify-content: center;
  -webkit-mask: url("../../web/img/right.svg") center / cover no-repeat;
  mask: url("../../web/img/right.svg") center / cover no-repeat;
  left: calc(50% - (var(--button-width) / 2));
  position: absolute;
  top: calc(50% - (var(--button-height) / 2));
  width: var(--button-width);
}

.wh-rtd-editor *:first-child
{
  margin-top: 10px;
}

.wh-rtd-editor > *:last-child
{
  margin-bottom: 20px;
}

.wh-rtd-editor .wh-rtd-embeddedobject,
.wh-rtd-editor .wh-rtd-embeddedobject th,
.wh-rtd-editor .wh-rtd-embeddedobject td
{
  font: 12px/16px Roboto, Helvetica, Arial, sans-serif;
}

.wh-rtd-editor .wh-rtd-embeddedobject p,
.wh-rtd-editor .wh-rtd-embeddedobject ul,
.wh-rtd-editor .wh-rtd-embeddedobject ol
{
  margin-top: 5px 0;
}

.wh-rtd-editor .wh-rtd-embeddedobject li
{
  margin: 0;
}
