.wh-form__fieldgroup:not(.wh-form__fieldgroup--hidden) {
  display: flex;
}

.wh-form__fieldgroup {
  align-items: baseline;
}

.wh-form__label, .wh-form__optiondata,
.wh-form__optionlabel,
.wh-form__subfieldlabel {
  display: inline-block;
}

.wh-form__label {
  flex-shrink: 0;
}

.wh-form__fields {
  flex: 1 0 0px;
}

.wh-form__fieldline {
  width: 100%;
  align-items: baseline;
  flex-wrap: wrap;
}

.wh-form__fieldline:not(.wh-form__fieldline--hidden) {
  display: inline-flex;
}

.wh-form__fieldline--nowrap:not(.wh-form__fieldline--hasinfotext) {
  flex-wrap: nowrap;
}

.wh-form__fieldgroup--horizontal .wh-form__fields {
  display: flex;
  flex-wrap: wrap;
}
.wh-form__fieldgroup--horizontal .wh-form__fieldline {
  flex: none;
  width: auto;
  max-width: 100%;
}

.wh-form__infotextwrapper {
  flex: 0 0 100%;
}

.wh-form__optiondata {
  display: inline-flex;
  width: 100%;
  align-items: baseline;
  flex-grow: 1;
  flex-wrap: wrap;
}
.wh-form__optiondata--vertical {
  width: 0;
}

.wh-form__subfield {
  display: flex;
  flex-grow: 1;
  align-items: baseline;
}

.wh-form__fieldline > input[type=checkbox],
.wh-form__fieldline > input[type=radio] {
  flex-shrink: 0;
}

.wh-form__fieldline > textarea {
  resize: vertical;
}

.wh-form__label {
  min-width: 240px;
}
.wh-form__fieldgroup--required .wh-form__label::after {
  content: "*";
}

.wh-form__fieldgroup {
  align-items: var(--form-fieldgroup-align);
  flex-direction: var(--form-fieldgroup-flex-direction);
  margin-top: 10px;
}

.wh-form__fieldline--nowrap + .wh-form__fieldline--nowrap {
  margin-left: 10px;
}

.wh-form__fields {
  width: var(--form-fields-width);
}
.wh-form__fieldgroup--horizontal .wh-form__fields {
  display: block;
}

.wh-form__buttongroup {
  margin-top: 10px;
}

.wh-styledinput button {
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--button-background-color);
  border: 1px solid var(--button-background-color);
  border-radius: 2px;
  box-shadow: none;
  cursor: pointer;
  color: var(--button-font-color);
  font: inherit;
  padding: 6px 10px;
}
.wh-styledinput button:active {
  background-color: transparent;
  color: var(--button-background-color);
}
.wh-styledinput label {
  align-self: flex-start;
  cursor: pointer;
  margin-top: 6px;
}
.wh-styledinput input[type=text],
.wh-styledinput input[type=password],
.wh-styledinput input[type=email],
.wh-styledinput input[type=number],
.wh-styledinput textarea {
  -webkit-appearance: none;
  appearance: none;
  background-color: #ffffff;
  border: 1px solid var(--input-border-color);
  box-shadow: inset 0 0 3px var(--input-border-color);
  border-radius: 2px;
  color: inherit;
  font: inherit;
  padding: 6px 10px;
  width: 100%;
}
.wh-styledinput input[type=text]:focus,
.wh-styledinput input[type=password]:focus,
.wh-styledinput input[type=email]:focus,
.wh-styledinput input[type=number]:focus,
.wh-styledinput textarea:focus {
  box-shadow: inset 0 0 3px var(--link-color);
  outline: none;
}
.wh-styledinput input[type=password] {
  max-width: 300px;
}
.wh-styledinput input[type=number] {
  max-width: 100px;
}
.wh-styledinput input[type=checkbox] {
  -webkit-appearance: none;
  appearance: none;
  background-color: #ffffff;
  border: 1px solid var(--input-border-color);
  box-shadow: inset 0 0 3px var(--input-border-color);
  border-radius: 2px;
  color: inherit;
  font: inherit;
  padding: 6px 10px;
  cursor: pointer;
  display: inline-block;
  height: 16px;
  margin-right: 1ch;
  padding: 0;
  position: relative;
  top: 2px;
  vertical-align: baseline;
  width: 16px;
}
.wh-styledinput input[type=checkbox]:focus {
  box-shadow: inset 0 0 3px var(--link-color);
  outline: none;
}
.wh-styledinput input[type=checkbox]::before, .wh-styledinput input[type=checkbox]::after {
  background-color: var(--input-element-color);
  border-radius: 2px;
  content: "";
  display: block;
  opacity: 0;
  position: absolute;
  transform: rotate(45deg);
  transition: opacity var(--input-transition-time);
}
.wh-styledinput input[type=checkbox]::before {
  bottom: 6px;
  left: 1px;
  right: 1px;
  top: 6px;
}
.wh-styledinput input[type=checkbox]::after {
  bottom: 1px;
  left: 6px;
  right: 6px;
  top: 1px;
}
.wh-styledinput input[type=checkbox]:checked::before, .wh-styledinput input[type=checkbox]:checked::after {
  opacity: 1;
}
.wh-styledinput input[type=checkbox]:disabled {
  opacity: 0.5;
}
.wh-styledinput input[type=radio] {
  -webkit-appearance: none;
  appearance: none;
  background-color: #ffffff;
  border: 1px solid var(--input-border-color);
  box-shadow: inset 0 0 3px var(--input-border-color);
  border-radius: 2px;
  color: inherit;
  font: inherit;
  padding: 6px 10px;
  border-radius: 100%;
  cursor: pointer;
  display: inline-block;
  height: 16px;
  margin-right: 1ch;
  padding: 0;
  position: relative;
  top: 2px;
  vertical-align: baseline;
  width: 16px;
}
.wh-styledinput input[type=radio]:focus {
  box-shadow: inset 0 0 3px var(--link-color);
  outline: none;
}
.wh-styledinput input[type=radio]::after {
  background-color: var(--input-element-color);
  border-radius: 100%;
  content: "";
  display: block;
  opacity: 0;
  position: absolute;
  transition: opacity var(--input-transition-time);
  bottom: 2px;
  left: 2px;
  right: 2px;
  top: 2px;
}
.wh-styledinput input[type=radio]:checked::after {
  opacity: 1;
}
.wh-styledinput input[type=radio]:disabled {
  opacity: 0.5;
}
.wh-styledinput select {
  -webkit-appearance: none;
  appearance: none;
  background-color: #ffffff;
  border: 1px solid var(--input-border-color);
  box-shadow: inset 0 0 3px var(--input-border-color);
  border-radius: 2px;
  color: inherit;
  font: inherit;
  padding: 6px 10px;
  cursor: pointer;
  overflow: hidden;
  padding-right: 32px;
  text-overflow: ellipsis;
  width: 100%;
}
.wh-styledinput select:focus {
  box-shadow: inset 0 0 3px var(--link-color);
  outline: none;
}
.wh-styledinput .wh-form__fieldgroup--pulldown .wh-form__fieldline {
  position: relative;
}
.wh-styledinput .wh-form__fieldgroup--pulldown .wh-form__fieldline::after {
  background: var(--button-background-color);
  content: "";
  height: 20px;
  -webkit-mask: url("../../web/img/right.svg") center/20px no-repeat;
  mask: url("../../web/img/right.svg") center/20px no-repeat;
  pointer-events: none;
  position: absolute;
  right: 10px;
  top: calc(50% - 10px);
  transform: rotate(90deg);
  width: 20px;
}

.wh-form__error {
  color: #cc0000;
}