.wh-rtd__img--floatleft {
  display: var(--image-float-display);
  float: var(--image-float-left);
  margin: var(--image-margin-right);
}

.wh-rtd__img--floatright {
  display: var(--image-float-display);
  float: var(--image-float-right);
  margin: var(--image-margin-left);
}