@charset "UTF-8";
.memberlist__list {
  column-gap: 10px;
  display: grid;
  grid-template-columns: var(--memberlist-grid);
  margin-top: 30px;
}

.memberlist__voice {
  margin-top: var(--memberlist-voice-margin);
}
.memberlist__voice:empty {
  display: none;
}

.memberlist__name {
  margin-top: var(--memberlist-member-margin);
}
.memberlist__name--first {
  margin-top: var(--memberlist-subrow-voice-margin);
}

.memberlist__address,
.memberlist__numbers,
.memberlist__email {
  margin-left: var(--memberlist-subrow-indent);
  margin-top: var(--memberlist-subrow-margin);
}
.memberlist__address--first,
.memberlist__numbers--first,
.memberlist__email--first {
  margin-top: var(--memberlist-subrow-voice-margin);
}
.memberlist__address:empty,
.memberlist__numbers:empty,
.memberlist__email:empty {
  display: none;
}

.memberlist__voice {
  align-self: start;
  font-weight: 700;
  grid-column: voice/span 1;
  margin-right: var(--memberlist-member-margin);
}

.memberlist__name {
  align-self: start;
  grid-column: name/span 1;
  margin-right: var(--memberlist-member-margin);
}

.memberlist__address {
  align-self: start;
  display: grid;
  grid-column: address/span 1;
  grid-template-columns: var(--memberlist-address-grid);
}

.memberlist__street-nr {
  align-self: start;
  grid-column: street-nr/span 1;
  margin-right: var(--memberlist-member-margin);
}

.memberlist__zip-city {
  align-self: start;
  grid-column: zip-city/span 1;
  margin-right: var(--memberlist-member-margin);
}

.memberlist__country {
  align-self: start;
  grid-column: country/span 1;
  margin-right: var(--memberlist-member-margin);
}

.memberlist__numbers {
  align-self: start;
  display: grid;
  gap: var(--memberlist-numbers-margin);
  grid-column: numbers/span 1;
  grid-template-columns: var(--memberlist-numbers-grid);
  white-space: nowrap;
}

.memberlist__phone {
  align-self: start;
  grid-column: phone/span 1;
}

.memberlist__mobile {
  align-self: start;
  grid-column: mobile/span 1;
}

.memberlist__email {
  align-self: start;
  grid-column: email/span 1;
  white-space: nowrap;
}

.memberlist__project-member {
  font-size: 0.75em;
  position: relative;
  top: -0.5em;
}
.memberlist__project-member::after {
  content: "🚩";
}