.wh-gallery {
  gap: 10px;
  justify-content: start;
  margin: 40px 0;
}
.main__widgets .wh-gallery {
  gap: 5px;
}
h2 + .wh-gallery {
  margin-top: 20px;
}

.wh-gallery__image {
  margin: 0;
  overflow: hidden;
  position: relative;
}
.wh-gallery__image a {
  display: block;
  line-height: 0;
}

.wh-gallery__imagecaption {
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  background: rgba(255, 255, 255, 0.8);
  bottom: 0;
  font-size: 0.9em;
  left: 0;
  line-height: 1.2em;
  max-height: 100%;
  opacity: 0;
  overflow: hidden;
  padding: 0.2em;
  position: absolute;
  right: 0;
  transition: opacity var(--menu-transition-time);
}
.wh-gallery__image:hover .wh-gallery__imagecaption {
  opacity: 1;
}

.wh-gallery-modal {
  z-index: var(--z-index-gallery);
}

.wh-gallery-modal__counter {
  display: none;
}

.wh-gallery-modal__close::after,
.wh-gallery-modal__previous::after,
.wh-gallery-modal__next::after {
  background: #ffffff;
  content: "";
  display: block;
  height: var(--gallery-icon-size);
  width: var(--gallery-icon-size);
}

.wh-gallery-modal__close {
  right: calc(30px + env(safe-area-inset-right));
  top: calc(30px + env(safe-area-inset-top));
}
.wh-gallery-modal__close::after {
  -webkit-mask: url("../../web/img/close.svg") center/var(--gallery-icon-size) no-repeat;
  mask: url("../../web/img/close.svg") center/var(--gallery-icon-size) no-repeat;
}

.wh-gallery-modal__previous {
  left: env(safe-area-inset-left);
}
.wh-gallery-modal__previous::after {
  -webkit-mask: url("../../web/img/right.svg") center/var(--gallery-icon-size) no-repeat;
  mask: url("../../web/img/right.svg") center/var(--gallery-icon-size) no-repeat;
  transform: rotate(180deg);
}

.wh-gallery-modal__next {
  right: env(safe-area-inset-right);
}
.wh-gallery-modal__next::after {
  -webkit-mask: url("../../web/img/right.svg") center/var(--gallery-icon-size) no-repeat;
  mask: url("../../web/img/right.svg") center/var(--gallery-icon-size) no-repeat;
}

.wh-gallery-modal__image {
  z-index: 0;
}
.wh-gallery-modal__image .title {
  background: rgba(255, 255, 255, 0.75);
  bottom: 0;
  font-size: 0.7em;
  line-height: 1.2em;
  padding: 0.2em;
  position: absolute;
  right: 0;
  z-index: 2;
}
.wh-gallery-modal__image img {
  z-index: 1;
}