@charset "UTF-8";
.eventslist__title {
  clear: both;
  margin: 0;
}
.eventslist__title:not(:first-child)::before {
  clear: both;
  content: "";
  display: block;
  height: 20px;
}

.eventslist__poster-counter {
  display: none;
}

.eventslist__poster {
  display: block;
  float: left;
  margin-bottom: 10px;
  margin-right: 20px;
}
.eventslist__poster-counter:nth-of-type(even) + .eventslist__title .eventslist__poster {
  float: right;
  margin-left: 20px;
  margin-right: 0;
}

.eventslist__metadata {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.eventslist__metadata *::before {
  content: "•";
  display: inline-block;
  margin-right: 0.5em;
}
.eventslist__metadata * + *::before {
  margin-left: 0.5em;
}
.eventslist__metadata--isstandalone {
  font-size: 0.9em;
  flex-direction: column;
}
.eventslist__metadata--isstandalone * + *::before {
  margin-left: 0;
}

.eventslist__loadmore {
  display: none;
  position: relative;
  text-align: right;
}
.eventslist__loadmore--moreavailable {
  display: block;
}
.dompack--busymodal .eventslist__loadmore::after {
  background: rgba(255, 255, 255, 0.5);
  bottom: 0;
  content: "";
  cursor: wait;
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}