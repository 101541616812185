@charset "UTF-8";
.sheetmusic__overview-row {
  display: flex;
  flex-direction: row;
  flex-wrap: var(--sheetmusic-row-flex-wrap);
  margin-top: 10px;
}
.main__widgets .sheetmusic__overview-row {
  flex-wrap: wrap;
}

.sheetmusic__overview-letter {
  flex: none;
  font-weight: 700;
  width: var(--sheetmusic-letter-width);
}

.sheetmusic__overview-composer {
  flex: var(--sheetmusic-composer-flex);
  padding-right: var(--sheetmusic-composer-padding);
  width: var(--sheetmusic-composer-width);
}
.main__widgets .sheetmusic__overview-composer {
  flex: 1;
  width: auto;
}

.sheetmusic__overview-title {
  flex: 1;
  flex-basis: 100%;
  padding-left: var(--sheetmusic-title-padding);
  position: relative;
  width: var(--sheetmusic-title-width);
}
.sheetmusic__overview-title::before {
  content: "🎵 ";
  display: inline-block;
  margin-left: calc(0px - var(--sheetmusic-icon-width));
  width: var(--sheetmusic-icon-width);
}
.main__widgets .sheetmusic__overview-title {
  padding-left: calc(var(--sheetmusic-letter-width) + 25px);
  width: 100%;
}